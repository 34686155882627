import { api } from '@/api';
export function getFeedbacks(params) {
    return api.get('/feedbacks', { params });
}

export function getFeedbackCounts(params) {
    return api.get('/feedbacks_count', { params });
}

export function readFeedbackCounts() {
    return api.post('/feedbacks_read');
}
