import * as Yup from 'yup';
import { isUndefinedOrEmptyStringOrNull } from '@/utils/reuseble';
import i18n from '@/i18n';
const { t } = i18n.global;

function getLabel(label) {
    return typeof label == 'string' ? label : label();
}

export function initVeeValidate() {
    Yup.setLocale({
        mixed: {
            required: (params) => {
                return !isUndefinedOrEmptyStringOrNull(params.label)
                    ? () =>
                          t('validation.fill', {
                              field: getLabel(params.label),
                          })
                    : () => t('validation.required');
            },
            notType: (params) =>
                params.type === 'number'
                    ? !isUndefinedOrEmptyStringOrNull(params.label)
                        ? () =>
                              t('validation.number', {
                                  field: getLabel(params.label),
                              })
                        : ``
                    : t('validation.required'),
        },
        number: {
            min: (params) =>
                t('validation.min', {
                    num: params.min,
                }),
            max: (params) =>
                t('validation.max', {
                    num: params.max,
                }),
            integer: () => `${t('validation.integer')}`,
        },
        string: {
            min: (params) =>
                t('validation.min_text', {
                    num: params.min,
                }),
            max: (params) =>
                t('validation.max_text', {
                    num: params.max,
                }),
            email: () => t('validation.email'),
        },
    });
}
