<template>
    <li
        class="nav-item align-self-center search-animated"
        :class="{ 'show-search': $store.state.is_show_search }"
        v-if="!isCallCenter() && !isCallCenterBoss() && !isCompanyLaborant()"
    >
        <svg
            @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-search toggle-search"
        >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <form class="form-inline search-full form-inline search" :class="{ 'input-focused': $store.state.is_show_search }">
            <div class="search-bar">
                <input
                    id="header-search-input"
                    type="search"
                    v-model="searchValue"
                    class="form-control search-form-control ms-lg-auto"
                    :placeholder="$t('btns.search')"
                />
            </div>
        </form>
    </li>
</template>

<script setup>
    import { isCallCenter, isCallCenterBoss, isCompanyLaborant } from '@/utils/role';
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();
    const searchValue = computed({
        // getter
        get() {
            return store.state.globalSearch;
        },
        // setter
        set(newValue) {
            store.commit('setGlobalSearch', newValue);
        },
    });
</script>

<style lang="scss" scoped></style>
