<template>
    <router-link to="/">
        <img src="@/assets/images/logo.svg" class="navbar-logo" alt="logo" />
        <img src="@/assets/images/logotext.svg" v-if="isDesktop" class="me-5" />
    </router-link>
</template>

<script setup>
    import { useWindowSize } from '@/composables/use-window';

    const { isDesktop } = useWindowSize();
</script>

<style lang="scss" scoped></style>
