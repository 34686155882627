import { authMiddleware, isCallCenterBoss } from '@/middleware';

export const CallCenterBoss = [
    {
        path: '/call-center-boss/feedbacks',
        name: 'call-center-boss-feedbacks',
        beforeEnter: [authMiddleware, isCallCenterBoss],
        component: () => import('@/views/call-center-boss/feedback'),
    },
];
