import { getFeedbackCounts } from '@/services/admin/feedback';
import { getFeedbackCountsCallCenterBoss } from '@/services/call-center-boss/feedback';
import { getTechnicalSupportCount } from '@/services/call-center-boss/technical-support';

export default {
    namespaced: true,
    state: {
        count: 0,
        countSupport: 0,
    },
    mutations: {
        setCounts(state, res) {
            state.count = res.data;
        },
        setCountsSupport(state, res) {
            state.countSupport = res.data;
        },
    },
    actions: {
        getCounts({ commit }) {
            getFeedbackCounts().then((res) => {
                commit('setCounts', res);
            });
        },
        getCountsForCallCenterBoss({ commit }) {
            getFeedbackCountsCallCenterBoss().then((res) => {
                commit('setCounts', res);
            });
        },
        getTechnicalSupportCounts({ commit }) {
            getTechnicalSupportCount().then((res) => {
                commit('setCountsSupport', res);
            });
        },
    },
    getters: {},
};
