// Essantional items
import axios from 'axios';
// Toast
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { Interceptors, InterceptorsWithWholeResponse } from './interceptors';

export const auth = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_URL}`,
});

const instanceGenerator = (baseUrl) => {
    const interceptors = new Interceptors();
    const _axios = axios.create({
        baseURL: baseUrl,
    });
    _axios.interceptors.request.use(interceptors.requestResolve, interceptors.requestReject);
    _axios.interceptors.response.use(interceptors.responseResolve, interceptors.responseReject);
    return _axios;
};

const instanceGeneratorResponseBlob = (baseUrl) => {
    const interceptors = new InterceptorsWithWholeResponse();
    const _axios = axios.create({
        baseURL: baseUrl,
        responseType: 'blob',
    });
    _axios.interceptors.request.use(interceptors.requestResolve, interceptors.requestReject);
    _axios.interceptors.response.use(interceptors.responseResolve, interceptors.responseReject);
    return _axios;
};

class Core {
    constructor(axios) {
        this._axios = axios;
    }

    get(url, config) {
        return this._axios.get(url, config).catch((error) => Promise.reject(error));
    }

    post(url, body = {}, config) {
        return this._axios
            .post(url, body, {
                ...config,
            })
            .catch((error) => Promise.reject(error));
    }

    put(url, body = {}) {
        return this._axios.put(url, body).catch((error) => Promise.reject(error));
    }

    delete(url, body = {}) {
        return this._axios.delete(url, body).catch((error) => Promise.reject(error));
    }
}

export const api = new Core(instanceGenerator(process.env.VUE_APP_API_SITE + '/admin'));

export const apiV1 = new Core(instanceGenerator(process.env.VUE_APP_MAIN_URL));

export const apiV1BlobResponse = new Core(instanceGeneratorResponseBlob(process.env.VUE_APP_MAIN_URL));

export const apiClinic = new Core(instanceGenerator('https://api.paramedics.uz/api/clinic'));
// Error handlers

const errorHandler = (error) => {
    console.log('An error occurred:', error?.response?.data?.message);
    toast.error(`${error?.response?.data?.message}`, {
        autoClose: 3000,
        toastStyle: {
            fontSize: '18px',
            fontWeight: '500',
            color: '#000',
        },
    });
};

auth.interceptors.response.use(
    (response) => response.data,
    (error) => {
        errorHandler(error);
        return Promise.reject(error);
    }
);
