<template>
    <BaseRow :gutter="[2, 2]">
        <div class="position-absolute end-0" style="z-index: 100">
            <BaseButton class="btn-primary my-2" @ok="changeSrcAndOpenModal(url1)">Гувоҳнома</BaseButton>

            <br />
            <BaseButton class="btn-primary" @ok="changeSrcAndOpenModal(url2)">Солиқ</BaseButton>
        </div>

        <BaseCol>
            <h6>"PARAMEDICS " MAS'ULIYATI CHEKLANGAN JAMIYAT</h6>
        </BaseCol>
        <BaseCol>
            <h6>Р/С: 20208000905637655001</h6>
        </BaseCol>
        <BaseCol>
            <h6>МФО: 00421</h6>
        </BaseCol>
        <BaseCol>
            <h6>Банк: ТОШКЕНТ Ш., "ИПАК ЙУЛИ" АИТ БАНКИНИНГ МИРЗО УЛУГБЕК ФИЛИАЛИ</h6>
        </BaseCol>
        <BaseCol>
            <h6>ДБИБТ : 79994</h6>
        </BaseCol>
        <BaseCol>
            <h6>ИФУТ: 63990</h6>
        </BaseCol>
        <BaseCol>
            <h6>КФС : КФС</h6>
        </BaseCol>
        <BaseCol>
            <h6>КОПФ: 152</h6>
        </BaseCol>
        <BaseCol>
            <h6>СОАТО: СОАТО</h6>
        </BaseCol>

        <BaseCol>
            <h6>ОКЭД : 63990</h6>
        </BaseCol>
        <BaseCol>
            <h6>ИНН: 310393915</h6>
        </BaseCol>
        <BaseCol>
            <h6>{{ $t('table.phone') }}: +9989555190303</h6>
        </BaseCol>
        <BaseCol>
            <h6>E-mail:<a href="mailto:    hello@paramedics.uz" target="_blank"> hello@paramedics.uz</a></h6>
        </BaseCol>
        <BaseCol>
            <h6>Веб-сайт:<a href="https://paramedics.uz" target="_blank">https://paramedics.uz</a></h6>
        </BaseCol>
        <BaseCol>
            <h6>Город Ташкент, Сайрам, 5-проезд/92</h6>
        </BaseCol>
        <BaseCol>
            <h6>Директор - Д.Д. Джураев</h6>
        </BaseCol>
    </BaseRow>

    <Modal width="900px" title="Реквизиты" @close="close" :is-open="isOpen">
        <template #body>
            <BaseLoading :loading="loading">
                <embed type="application/pdf" :src="fileSrc" v-if="fileSrc" class="w-100" style="height: 77vh" />
            </BaseLoading>
        </template>
    </Modal>
</template>

<script setup>
    import { ref } from 'vue';
    import BaseButton from './custom/BaseButton.vue';
    import BaseCol from './custom/BaseCol.vue';
    import BaseRow from './custom/BaseRow.vue';
    import Modal from './custom/Modal.vue';
    import BaseLoading from './custom/BaseLoading.vue';
    import { debounce } from '@/utils/debounce';
    import { useModal } from '@/composables/use-modal';

    const url1 = '/file/Certificate.pdf';
    const url2 = '/file/Certificate_of_registration.pdf';

    const fileSrc = ref(url1);
    const loading = ref(false);
    const { open, isOpen, close } = useModal();

    const changeSrcAndOpenModal = (param) => {
        open();
        loading.value = true;
        fileSrc.value = null;

        debounce(() => {
            fileSrc.value = param;

            loading.value = false;
        }, 500)();
    };
</script>

<style lang="scss" scoped></style>
