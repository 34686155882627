<template>
    <Col
        :class="[
            itemsCcenter ? 'd-flex flex-wrap align-items-center' : '',
            center ? 'd-flex flex-wrap align-items-center justify-content-center' : '',
            justifyCenter ? 'd-flex flex-wrap justify-content-center' : '',
            justifyBetween ? 'd-flex flex-wrap justify-content-between' : justifyEnd ? 'd-flex justify-content-end' : '',
        ]"
        :xs="24"
        :sm="24"
        :md="md"
        :lg="lg"
        :xl="xl"
        ><slot
    /></Col>
</template>

<script setup>
    import { Col } from 'ant-design-vue';
    import 'ant-design-vue/lib/col/style';
    import { defineProps, computed } from 'vue';
    const props = defineProps({
        col: {
            type: Number,
            default: 24,
        },
        center: {
            type: Boolean,
            default: false,
        },
        justifyCenter: {
            type: Boolean,
            default: false,
        },
        justifyEnd: {
            type: Boolean,
            default: false,
        },
        justifyBetween: {
            type: Boolean,
            default: false,
        },
        itemsCenter: {
            type: Boolean,
            default: false,
        },
        xl: {
            default: null,
            type: Number,
        },
        lg: {
            default: null,
            type: Number,
        },
    });

    const md = computed(() => (props.col > 12 ? 24 : 12));
    const xl = computed(() => props.xl ?? props.col);
    const lg = computed(() => props.lg ?? props.xl ?? props.col);
</script>

<style lang="scss" scoped>
    .flex-wrap {
        flex-wrap: wrap;
    }
</style>
