<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar flex-nowrap navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <AppLogo />
                    </li>
                </ul>
                <AppSearchIcon />
                <ul class="navbar-item flex-row align-items-center ms-md-0 ms-auto">
                    <AppSearchInput  />
                    <BaseButton v-if="isAdmin()" @click="$router.push('/admin/admins')" icon="admins" class="btn-primary mx-2">
                        {{ $t('admin.sidebar.admins') }}</BaseButton
                    >
                    <BaseButton v-if="isAdmin()" icon="sidebar/clinic" @click="$router.push('/admin/clinic-login')" class="btn-outline-primary">{{
                        $t('admin.sidebar.clinic')
                    }}</BaseButton>
                </ul>
                <BaseButton v-if="isAdmin()" icon="notification" @click="$router.push('/admin/notifications')" class="btn-primary">{{
                    $t('admin.sidebar.notifications')
                }}</BaseButton>
                <BaseButton v-if="isAdmin()" icon="sms" @click="$router.push('/admin/messages')" class="btn-primary">{{ $t('table.message') }}</BaseButton>

                <div class="navbar-item flex-row ms-md-auto align-items-center">
                    <BaseButton v-if="isAdmin()" icon="company-info" @click="open" class="btn-primary mx-2">Реквизиты</BaseButton>
                    <BaseButton v-if="isCompany()" @click="$router.push('/company/discount')" class="btn-primary">{{ $t('table.discount') }}</BaseButton>
                    <AppUserName />

                    <BaseLocaleDropdown />
                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <BaseAvatar :src="$store.state.imgAdmin" />
                        </a>

                        <AppDropdownItems />
                    </div>
                </div>
            </header>
        </div>

        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <AppSidebarExpand />
                <div id="breadcrumb" class="vue-portal-target md-flex-col"></div>
            </header>
        </div>
        <!--  END NAVBAR  -->

        <Modal width="1100px" title="Реквизиты" @close="close" :is-open="isOpen">
            <template #body>
                <Requisites />
            </template>
        </Modal>
    </div>
</template>

<script setup>
    import BaseLocaleDropdown from '@/components/custom/BaseLocaLeDropdown.vue';
    import BaseButton from '@/components/custom/BaseButton.vue';
    import BaseAvatar from '../custom/BaseAvatar.vue';

    import AppSidebarExpand from '@/components/app/AppSidebarExpand.vue';
    import AppDropdownItems from '@/components/app/AppDropdownItems.vue';
    import AppSearchInput from '@/components/app/AppSearchInput.vue';
    import AppSearchIcon from '@/components/app/AppSearchIcon.vue';
    import AppUserName from '@/components/app/AppUserName.vue';
    import AppLogo from '@/components/app/AppLogo.vue';

    import Requisites from '@/components/Requisites.vue';
    import Modal from '@/components/custom/Modal.vue';

    import { onMounted, ref } from 'vue';

    import { isAdmin, isCompany } from '@/utils/role';
    import { useModal } from '@/composables/use-modal';

    const { open, isOpen, close } = useModal();

    const selectedLang = ref(null);

    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };
</script>

<style>
    @media screen and (max-width: 700px) {
        .role-name {
            display: none;
        }
    }
</style>
