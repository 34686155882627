<template>
    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
        <li role="presentation">
            <router-link to="#" @click="logout" class="dropdown-item">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-out"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>

                {{ $t('btns.logout') }}
            </router-link>
        </li>
        <li role="presentation" v-if="isCompany()">
            <router-link to="/company/profile" @click="$router.push(`/company/profile`)" class="d-flex align-items-center dropdown-item">
                <BaseSvgWrapper name="coffee" />

                {{ $t('table.profile') }}
            </router-link>
        </li>
    </ul>
</template>

<script setup>
    import BaseSvgWrapper from '../custom/BaseSvgWrapper.vue';
    import { isCompany } from '@/utils/role';
    import $cookies from 'vue-cookies';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    function logout() {
        $cookies.remove('user_token');
        $cookies.remove('user');
        router.push('/auth/login');
    }
</script>

<style lang="scss" scoped></style>
3
