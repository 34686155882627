import { getCurrentUserRole } from '@/utils/role';
import { useHead } from '@vueuse/head';
import { unref, computed } from 'vue';
import i18n from '@/i18n';

let siteTitle = 'hello';
let separator = '|';

export const usePageTitle = (pageTitle) =>
    useHead(
        computed(() => ({
            title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
        }))
    );

export const useMeta = (data) => {
    return useHead({
        ...data,
        title: `${i18n.global.te(`role.${getCurrentUserRole()}`) ? i18n.global.t(`role.${getCurrentUserRole()}`) : 'Парамедикс'}  / ${data.title}`,
    });
};
