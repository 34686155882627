import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export function showMessage(message, type = 'success', position = toast.POSITION.TOP_RIGHT) {
    toast[type](message, {
        autoClose: 3000,
        position,
        toastStyle: {
            fontSize: '18px',
            fontWeight: '500',
            color: '#000',
        },
    });
}
