<template>
    <div class="svg-wrapper" v-if="name">
        <InlineSvg @loaded="svgLoaded($event)" @unloaded="svgUnloaded()" @error="svgLoadError($event)" :src="require(`@/assets/svg/${name}.svg`)" />
    </div>
</template>

<script setup>
    import InlineSvg from 'vue-inline-svg';
    import { defineProps, computed } from 'vue';
    const props = defineProps({
        name: {
            type: String,
            default() {
                return null;
            },
        },
    });

    const name = computed(() => {
        return props.name;
    });

    function svgLoaded() {
        console.log('Loaded');
    }

    function svgUnloaded() {
        console.log('svgUnloaded');
    }

    function svgLoadError() {
        console.log('svgLoadError');
    }
</script>

<style scoped lang="scss">
    .svg-wrapper {
        svg {
            width: 25px;
            height: 25px;
        }
    }
</style>
