import { api } from '@/api';
export function getChats(params) {
    return api.get('/call_center_boss/chat/history', { params });
}
export function getChatCountsCallCenterBoss(params) {
    return api.get('/call_center_boss/chat/history/counter', { params });
}
export function getChatMessages(id, params) {
    return api.get(`/call_center_boss/chat/${id}/show`, { params });
}
export function unblockContact(id) {
    return api.post(`/call_center_boss/chat/${id}/unblock`);
}
