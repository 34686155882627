import { getChatCounts } from '@/services/admin/chat';
import { getChatCountsCallCenterBoss } from '@/services/call-center-boss/chat';
export default {
    namespaced: true,
    state: {
        newChatCount: 0,
        blockChatCount: 0,
        endedChatCount: 0,
        allChatCount: 0,
        acceptedChatCount: 0,
        gpCount: 0,
        botChatCount: 0,
    },
    mutations: {
        setChatCounts(state, res) {
            state.newChatCount = res.data.new;
            state.blockChatCount = res.data.blocked;
            state.endedChatCount = res.data.ended;
            state.allChatCount = res.data.all;
            state.acceptedChatCount = res.data.accepted;
            state.botChatCount = res.data.bot;
        },
        setGpCount(state) {
            state.gpCount += 1;
        },
        clearGpCount(state) {
            state.gpCount = 0;
        },
    },
    actions: {
        getCounts({ commit }) {
            getChatCounts().then((res) => {
                commit('setChatCounts', res);
            });
        },
        getChatCountsForCallCenterBoss({ commit }) {
            getChatCountsCallCenterBoss().then((res) => {
                commit('setChatCounts', res);
            });
        },
        
    },
    getters: {},
};
