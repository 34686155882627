import $cookies from 'vue-cookies';
// Toast
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import router from '@/router';
import { isCLDomain } from '@/utils/role';

export class Interceptors {
    requestResolve(config) {
        config.headers['Authorization'] = `Bearer ${$cookies.get('user_token') || null}`;
        return config;
    }

    requestReject(error) {
        return Promise.reject(error);
    }

    responseResolve(response) {
        return response.data;
    }

    async responseReject(error) {
        console.log('An error occurred:', error?.response?.data?.message);
        if (error?.response?.status == 424) {
            return Promise.reject(error);
        }

        if (error?.response?.status == 402 && isCLDomain()) {
            toast.error(`${error?.response?.data?.message}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
            return Promise.reject({ success: true });
        }
        if (error?.response?.data?.message) {
            toast.error(`${error?.response?.data?.message}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        } else {
            toast.error(`${error}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        }

        if (error.response.status === 401) {
            $cookies.remove('user');
            $cookies.remove('user_token');
            router.push('/auth/login');
        } else {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
}

export class InterceptorsWithWholeResponse {
    requestResolve(config) {
        config.headers['Authorization'] = `Bearer ${$cookies.get('user_token') || null}`;
        return config;
    }

    requestReject(error) {
        return Promise.reject(error);
    }

    responseResolve(response) {
        return response;
    }

    async responseReject(error) {
        console.log('An error occurred:', error?.response?.data?.message);
        if (error?.response?.data?.message) {
            toast.error(`${error?.response?.data?.message}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        } else {
            toast.error(`${error}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        }

        if (error.response.status === 401) {
            $cookies.remove('user');
            $cookies.remove('user_token');
            router.push('/auth/login');
        } else {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
}
