import { ref, onMounted, computed, onUnmounted } from 'vue';

export function useWindowSize() {
    const width = ref(window.innerWidth);
    const height = ref(window.innerHeight);

    const updateWindowSize = () => {
        width.value = window.innerWidth;
        height.value = window.innerHeight;
    };

    const isMobile = computed(() => {
        return width.value <= 764;
    });

    const isMobileTablet = computed(() => {
        return width.value <= 900 && width.value > 764;
    });

    const isTablet = computed(() => {
        return width.value <= 1300 && width.value > 900;
    });

    const isDesktop = computed(() => {
        return width.value > 1300;
    });
    onMounted(() => {
        window.addEventListener('resize', updateWindowSize);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateWindowSize);
    });

    return { width, height, isMobile, isDesktop, isTablet, isMobileTablet };
}
