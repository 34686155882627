import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import App from './App';
import { initSocket } from '@/services/socket';
const app = createApp(App);
// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from '@/i18n';

import VueCookies from 'vue-cookies';

import { initVeeValidate } from '@/plugins/vee-validate';
// set default settings
import appSetting from '@/app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();
initVeeValidate();

initSocket();

import CKEditor from '@ckeditor/ckeditor5-vue';

export const application = app.use(router).use(store).use(i18n).use(PerfectScrollbar).use(Maska).use(VueCookies).use(head).use(CKEditor);
