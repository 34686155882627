import { api } from '@/api';
export function getChats(params) {
    return api.get('/chat/history', { params });
}
export function getChatCounts(params) {
    return api.get('/chat/history/counter', { params });
}
export function getChatMessages(id, params) {
    return api.get(`/chat/${id}/show`, { params });
}

export function acceptChat(body) {
    return api.post(`/chat/accept`, body);
}

export function blockChat(body) {
    return api.post(`/chat/block`, body);
}

export function endChat(body) {
    return api.post(`/chat/end`, body);
}
export function sendChatMessage(id, data) {
    return api.post(`/chat/${id}/message`, data);
}
export function deleteChatMessage(id) {
    return api.post(`/chat/${id}/delete`);
}

export function editChatMessage(id, body) {
    return api.post(`/chat/${id}/edit`, body);
}
