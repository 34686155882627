<template>
    <div class="dropdown nav-item language-dropdown btn-group">
        <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
            <!-- <span class="mx-2">{{ selectedLang.name }}</span> -->
        </a>
        <ul class="dropdown-menu" aria-labelledby="ddllang">
            <perfect-scrollbar>
                <li v-for="item in $store.state.countryList" :key="item.code">
                    <a href="javascript:;" class="dropdown-item d-flex align-items-center" :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
                        <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span class="mx-2">{{ item.name }}</span>
                    </a>
                </li>
            </perfect-scrollbar>
        </ul>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const i18n = useI18n();
    const selectedLang = ref(window.$appSetting.toggleLanguage());
    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item?.code;
    };
</script>

<style lang="scss" scoped>
    .flag-width {
        width: 20px;
    }
</style>
