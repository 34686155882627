import $cookies from 'vue-cookies';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
import { io } from 'socket.io-client';

export function initSocket() {
    const socket = io('https://paramedics.uz:4000/');
    // client-side
    socket.on('connect', () => {
        console.log('connected');
    });
    socket.on('disconnect', () => {
        console.log('disconnected');
    });
    window.SocketClient = socket;
    // window.Pusher = Pusher;
    // window.Echo = new Echo({
    //     broadcaster: 'pusher',
    //     key: 'anyKey',
    //     wsHost: 'api.paramedics.uz',
    //     authEndpoint: 'https://api.paramedics.uz/laravel-websockets',
    //     encrypted: true,
    //     forceTLS: false,
    //     wsPort: 6001,
    //     wssPort: 6001,
    //     disableStats: true,
    //     enabledTransports: ['ws', 'wss'],
    //     cluster: 'eu',
    //     auth: {
    //         headers: {
    //             authorization: 'Bearer ' + $cookies.get('user_token'),
    //         },
    //     },
    // });
}

export function useSocket(callback) {
    if (window.SocketClient) {
        const userId = $cookies.get('user')?.id;

        window.SocketClient.on(`chat_${userId}`, (e) => {
            callback(JSON.parse(e));
        });
    }
    // if (window.Echo) {
    //     const userId = $cookies.get('user')?.id;

    //     window.Echo.channel(`Chat.${userId}`).listen('ChatEvent', (e) => {
    //         callback(e);
    //     });
    // }
}
export function useOrderChangedSocket(callback) {
    if (window.SocketClient) {
        const userId = $cookies.get('user')?.id;

        window.SocketClient.on(`order_channel_${userId}`, (e) => {
            callback(JSON.parse(e));
        });
    }

    // if (window.Echo) {
    //     const userId = $cookies.get('user')?.id;
    //     window.Echo.channel(`order_channel_${userId}`).listen('OrderChangedEvent', (e) => {
    //         callback(e);
    //     });
    // }
}
export function useOrderSocket(callback) {
    if (window.SocketClient) {
        window.SocketClient.on('order_channel', (e) => {
            callback(JSON.parse(e));
        });
    }
    // if (window.Echo) {
    //     window.Echo.channel(`order_channel`).listen('OrderChangedEvent', (e) => {
    //         callback(e);
    //     });
    // }
}
export function useOrderCreatedSocket(callback) {
    if (window.SocketClient) {
        const userId = $cookies.get('user')?.id;

        window.SocketClient.on(`order_created_${userId}`, (e) => {
            callback(JSON.parse(e));
        });
    }
    // if (window.Echo) {
    //     const userId = $cookies.get('user')?.id;
    //     window.Echo.channel(`order_created_${userId}`).listen('OrderCreatedEvent', (e) => {
    //         callback(e);
    //     });
    // }
}

export function useGPSocket(callback) {
    if (window.SocketClient) {
        window.SocketClient.on('gp_channel', (e) => {
            callback(JSON.parse(e));
        });
    }
    // if (window.Echo) {
    //     window.Echo.channel('gp_channel').listen('GpEvent', (e) => {
    //         callback(e);
    //     });
    // }
}

export function useChatSocket(callback) {
    if (window.SocketClient) {
        window.SocketClient.on('chat_channel', (e) => {
            callback(JSON.parse(e));
        });
    }

    // if (window.Echo) {
    //     window.Echo.channel('chat_channel')
    //         .listen('ChatUpdatedEvent', (e) => {
    //             callback(e);
    //         })
    //         .listen('ChatEvent', (e) => {
    //             callback(e);
    //         });
    // }
}

export function useFeedbackSocket(callback) {
    if (window.SocketClient) {
        window.SocketClient.on('feedback_channel', (e) => {
            callback(JSON.parse(e));
        });
    }

    // if (window.Echo) {
    //     window.Echo.channel('feedback_channel').listen('FeedbackEvent', (e) => {
    //         callback(e);
    //     });
    // }
}

export function useClinicOrderNotifyChannel(callback) {
    if (window.SocketClient) {
        window.SocketClient.on('clinic_order_notify_channel', (e) => {
            callback(JSON.parse(e)?.order_id);
        });
    }

    // if (window.Echo) {
    //     window.Echo.channel(`clinic_order_notify_channel`).listen('ClinicOrderNotifyEvent', (e) => {
    //         callback(e.order_id);
    //     });
    // }
}
