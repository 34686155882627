import { createRouter, createWebHistory } from 'vue-router';

const Home = () => import('@/views/index.vue');
const OrderView = () => import('@/views/public/order/id/view.vue');

// Modules
import { Auth } from './modules/auth';
import { Admin } from './modules/admin';
import { Errors } from './modules/errors';
import { authMiddleware } from '@/middleware';
import { Company } from './modules/company';
import { CallCenterBoss } from './modules/call-center-boss';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: [authMiddleware],
    },
    {
        path: '/public/order/:id',
        name: 'PublicOrderView',
        component: OrderView,
        meta: { layout: 'auth' },
    },

    //dashboard
    ...Admin,
    ...Errors,

    ...Auth,
    ...Company,
    ...CallCenterBoss,
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return { left: 0, top: 0 };
    //     }
    // },
});

export default router;
