<template>
    <Button :loading="loading" @click="ok" v-if="isMobile && icon" type="primary">
        <template #icon>
            <BaseSvgWrapper :name="icon" />
        </template>
    </Button>
    <button v-else class="btn mr-1 btn-custom-padding" :disabled="loading" @click="ok">
        <span v-show="loading" class="spinner-border spinner-border-sm text-white me-2 align-self-center loader-sm"></span>
        <slot />
    </button>
</template>

<script setup>
    import { useWindowSize } from '@/composables/use-window';
    import { defineEmits, defineExpose, ref } from 'vue';
    import { Button } from 'ant-design-vue';
    import 'ant-design-vue/lib/button/style';
    import BaseSvgWrapper from './BaseSvgWrapper.vue';
    const { isMobile } = useWindowSize();
    const emits = defineEmits(['ok']);
    const loading = ref(false);

    defineProps({
        icon: {
            default: null,
            type: String,
        },
    });
    function ok() {
        emits('ok', (v) => {
            loading.value = v;
        });
    }

    defineExpose({
        ok,
    });
</script>

<style>
    .btn-custom-padding {
        padding: 0.26rem 1.05rem !important;
    }
    .mr-1 {
        margin-right: 5px !important;
    }
</style>
