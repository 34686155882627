import { api } from '@/api';
export function getFeedbacks(params) {
    return api.get('/call_center_boss/feedbacks', { params });
}

export function getFeedbackCountsCallCenterBoss(params) {
    return api.get('/call_center_boss/feedbacks_count', { params });
}

export function readFeedbackCounts() {
    return api.post('/call_center_boss/feedbacks_read');
}
