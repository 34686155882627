import { ref } from 'vue';

export const useModal = () => {
    const isOpen = ref();
    const close = () => {
        isOpen.value = false;
    };
    const open = () => {
        isOpen.value = true;
    };

    return {
        isOpen,
        close,
        open,
    };
};
