<template>
    <div>
        <transition name="fade">
            <div v-if="props.isOpen" class="modal fade show" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
                <div :style="`width: ${isMobile || isMobileTablet ? '95%' : width}`" class="modal-dialog modal-dialog-centered" role="document">
                    <div @click.stop class="modal-content">
                        <div class="modal-header d-flex justify-content-between">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <slot name="title">{{ props.title }}</slot>
                            </h5>
                            <slot name="middle" />
                            <div>
                                <slot name="header-right" />
                                <button @click="$emit('close')" type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                            </div>
                        </div>
                        <div class="modal-body" style="max-height: 86vh; overflow: auto">
                            <slot name="body"></slot>
                        </div>
                        <div v-if="props.footer" class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';

    import { useWindowSize } from '@/composables/use-window';

    const { isMobile, isMobileTablet } = useWindowSize();
    const props = defineProps({
        isOpen: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Title',
        },
        footer: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '500px',
        },
    });
</script>

<style scoped>
    .modal {
        background-color: rgba(0, 0, 0, 0.384) !important;
        z-index: 1030 !important;
    }

    .modal-content {
        z-index: 1030 !important;
    }

    .show {
        display: block !important;
    }

    .modal-dialog {
        max-width: none !important;
    }
</style>
