import { api } from '@/api';
export function getTechnicalSupportList(params) {
    return api.get('/user_contacts', { params });
}

export function setTechnicalSupportDone(id) {
    return api.post(`/user_contacts/${id}/done`);
}

export function getTechnicalSupportCount() {
    return api.get(`/counters/user_contacts`);
}
