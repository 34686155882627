import { authMiddleware, isCompany, checkLoginAndToken, isCompanyOrCompanyManager, isCompanyOrCompanyManagerOrCompanyLaborant } from '@/middleware';
const Login = { template: '<div>LOGIN</div>' };
export const Company = [
    {
        path: '/company/clinic-login/:token',
        name: 'clinic-login-token',
        beforeEnter: [checkLoginAndToken],
        component: Login,
    },
    {
        path: '/company/profile',
        name: 'Profile',
        beforeEnter: [authMiddleware, isCompany],
        component: () => import('@/views/company/profile'),
    },
    {
        path: '/company/reports',
        name: 'Reports',
        beforeEnter: [authMiddleware, isCompanyOrCompanyManager],
        component: () => import('@/views/company/reports'),
    },
    {
        path: '/company/discount',
        name: 'discount',
        beforeEnter: [authMiddleware, isCompany],
        component: () => import('@/views/company/discount'),
    },
    {
        path: '/company/analyzes',
        name: 'Analyzes',
        beforeEnter: [authMiddleware, isCompanyOrCompanyManager],
        component: () => import('@/views/company/analyzes'),
    },

    // {
    //     path: '/company/archive',
    //     name: 'Archive',
    //     beforeEnter: [authMiddleware, isCompany],
    //     component: () => import('@/views/company/archive'),
    // },
    {
        path: '/company/orders',
        name: 'CompanyOrders',
        beforeEnter: [authMiddleware, isCompanyOrCompanyManagerOrCompanyLaborant],
        component: () => import('@/views/company/orders'),
    },
    {
        path: '/company/orders/:id',
        name: 'Order-View',
        beforeEnter: [authMiddleware, isCompanyOrCompanyManagerOrCompanyLaborant],
        component: () => import('@/views/company/orders/id/view'),
    },
];
